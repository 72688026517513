<template>
    <div class="modal">
        <div class="ventanas">
            <div class="ventana" v-for="(item,index) in cache.ventanas" :key="index">
                <button class="cerrar" @click="cerrar(index)">x</button>
                <DetalleEmpresa :id="item.id" v-if="item.tabla==='empresas'" :datos="item.datos"/>
                <DetallePresupuesto :id="item.id" v-if="item.tabla==='presupuestos'" :datos="item.datos" />
            </div>
        </div>
    </div>
</template>

<script>
import DetalleEmpresa from '@/views/DetalleEmpresa.vue'
import DetallePresupuesto from '@/views/DetallePresupuesto.vue'
import { mapState } from 'vuex'
export default {
    name: 'Modal',
    components:{
        DetalleEmpresa,
        DetallePresupuesto
    },
    computed:{
        ...mapState(['cache','detalles','listas','tablas'])
    },
    methods:{
        salir(){
            this.cache.ventanas = []
            this.$emit('salir')
        },
        cerrar(i){
            this.cache.ventanas.splice(i,1)
            if(this.cache.ventanas.length<=0){
                this.salir()
            }
        }
    }
}
</script>

<style scoped>
.modal{
    overflow-y: scroll;
    opacity: 1;
    background: rgba(0,0,0,0.8);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    margin: 0;
    color: white;
    position: absolute;
    z-index: 15000;
}
.ventanas{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ventana{
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
    box-shadow: 1px 1px 1px inset;
    width: fit-content;
    height: fit-content;
}
.cerrar{
    font-size: 120%;
    cursor: pointer;
    border: none;
    color: black;
    background: transparent;
    float: right;
    outline: none;
}
</style>